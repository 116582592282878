import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from 'react-router-dom';
import './Navigation.css';

function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(getSelectedTab(location.pathname));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  React.useEffect(() => {
    setValue(getSelectedTab(location.pathname));
  }, [location]);

  function getSelectedTab(pathname) {
    if (pathname === '/task-activity') return 'task-activity';
    if (pathname === '/schedule') return 'schedule';
    return 'home';
  }

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation value={value} onChange={handleChange} className="bottomNavigation" style={{ background: '#352f44' }}>
        <BottomNavigationAction
          label="Home"
          value="home"
          icon={<HomeIcon />}
          sx={{
            color: value === 'home' ? '#ffffff' : '#8a8a8a',
            '&.Mui-selected': {
              color: '#ffffff',
              backgroundColor: '#352f44',
            },
          }}
          onClick={() => navigate('/')}
        />
        <BottomNavigationAction
          label="Task & Activity"
          value="task-activity"
          icon={<AssignmentIcon />}
          sx={{
            color: value === 'task-activity' ? '#ffffff' : '#8a8a8a',
            '&.Mui-selected': {
              color: '#ffffff',
              backgroundColor: '#352f44',
            },
          }}
          onClick={() => navigate('/task-activity')}
        />
        <BottomNavigationAction
          label="Schedule"
          value="schedule"
          icon={<ScheduleIcon />}
          sx={{
            color: value === 'schedule' ? '#ffffff' : '#8a8a8a',
            '&.Mui-selected': {
              color: '#ffffff',
              backgroundColor: '#352f44',
            },
          }}
          onClick={() => navigate('/schedule')}
        />
      </BottomNavigation>
    </Paper>
  );
}

export default Navigation