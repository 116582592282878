import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import './Header.css';

function Header() {
    return (
        <AppBar position="fixed" style={{ background: '#352f44' }} className="appBar">
            <Toolbar>
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                >
                    XI MATH STUDENT
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default Header