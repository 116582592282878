import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { format, isToday, isTomorrow, addDays } from 'date-fns';
import { id as localeID } from 'date-fns/locale';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import './Home.css';

function Home() {
  const [todaySchedule, setTodaySchedule] = useState([]);
  const [tomorrowSchedule, setTomorrowSchedule] = useState([]);
  const [todayDeadlines, setTodayDeadlines] = useState([]);
  const [tomorrowDeadlines, setTomorrowDeadlines] = useState([]);
  const [thisWeekDeadlines, setThisWeekDeadlines] = useState([]);
  const [loading, setLoading] = useState(true); // State untuk loading

  useEffect(() => {
    const fetchScheduleData = () => {
      const todayRef = db.ref(`list_mapel/${getDay(0)}`);
      todayRef.once('value', (snapshot) => {
        if (snapshot.exists()) {
          setTodaySchedule(snapshot.val());
        } else {
          setTodaySchedule([]);
        }
      });

      const tomorrowRef = db.ref(`list_mapel/${getDay(1)}`);
      tomorrowRef.once('value', (snapshot) => {
        if (snapshot.exists()) {
          setTomorrowSchedule(snapshot.val());
        } else {
          setTomorrowSchedule([]);
        }
      });

      const deadlinesRef = db.ref('list_tugas');
      deadlinesRef.once('value', (snapshot) => {
        const deadlines = snapshot.val();
        const today = new Date();
        const startOfWeek = getStartOfWeek(today);
        const endOfWeek = getEndOfWeek(today);

        const todayDeadlines = [];
        const tomorrowDeadlines = [];
        const thisWeekDeadlines = [];

        for (const deadlineId in deadlines) {
          const deadline = deadlines[deadlineId];
          const deadlineDate = new Date(deadline.deadline);

          if (isToday(deadlineDate)) {
            todayDeadlines.push({
              ...deadline,
              deadline: formatDate(deadlineDate),
            });
          } else if (isTomorrow(deadlineDate)) {
            tomorrowDeadlines.push({
              ...deadline,
              deadline: formatDate(deadlineDate),
            });
          }

          if (deadlineDate >= startOfWeek && deadlineDate <= endOfWeek) {
            thisWeekDeadlines.push({
              ...deadline,
              deadline: formatDate(deadlineDate),
            });
          }
        }

        setTodayDeadlines(todayDeadlines);
        setTomorrowDeadlines(tomorrowDeadlines);
        setThisWeekDeadlines(thisWeekDeadlines);
        setLoading(false); // Set loading to false after data fetch
      });
    };

    fetchScheduleData();
  }, []);

  const formatDate = (date) => {
    if (isToday(date)) {
      return format(date, "HH:mm 'WIT'", { locale: localeID });
    } else {
      return format(date, "dd/MM/yyyy HH:mm 'WIT'", { locale: localeID });
    }
  };

  const getDay = (offset) => {
    const today = new Date();
    const options = { weekday: 'long', timeZone: 'Asia/Jayapura', locale: localeID };
    return new Intl.DateTimeFormat('id-ID', options).format(new Date(today.setDate(today.getDate() + offset)));
  };

  const getFormattedDate = (offset) => {
    const today = new Date();
    const newDate = addDays(today, offset);
    return format(newDate, 'EEEE', { locale: localeID });
  };

  const getStartOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  };

  const getEndOfWeek = (date) => {
    const startOfWeek = getStartOfWeek(date);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);
    return endOfWeek;
  };

  return (
    <div className="home-container">
      <div className="info">
        <h2>Jadwal Pelajaran</h2>
        <fieldset className="info-box">
          <legend>Hari Ini · {getFormattedDate(0)}</legend>
          <SkeletonTheme baseColor="#2a2438" highlightColor="#1a1622">
            {loading ? (
              <Skeleton count={4} height={20} />
            ) : (
              <div className="fade-in">
                {todaySchedule.length > 0 ? (
                  <div className="transparent-table-wrapper">
                    <table className="transparent-table">
                      <thead>
                        <tr>
                          <th>Mapel</th>
                          <th>Ruangan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {todaySchedule.map((data, index) => (
                          <tr key={index}>
                            <td>{data.mapel}</td>
                            <td>{data.ruangan}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h3><b>LIBUR</b></h3>
                )}
              </div>
            )}
          </SkeletonTheme>
        </fieldset>
        <fieldset className="info-box">
          <legend>Besok · {getFormattedDate(1)}</legend>
          <SkeletonTheme baseColor="#2a2438" highlightColor="#1a1622">
            {loading ? (
              <Skeleton count={4} height={20} />
            ) : (
              <div className="fade-in">
                {tomorrowSchedule.length > 0 ? (
                  <div className="transparent-table-wrapper">
                    <table className="transparent-table">
                      <thead>
                        <tr>
                          <th>Mapel</th>
                          <th>Ruangan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tomorrowSchedule.map((data, index) => (
                          <tr key={index}>
                            <td>{data.mapel}</td>
                            <td>{data.ruangan}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h3><b>LIBUR</b></h3>
                )}
              </div>
            )}
          </SkeletonTheme>
        </fieldset>
      </div>
      <div className="info">
        <h2>Deadline Tugas</h2>
        <fieldset className="info-box">
          <legend>Hari Ini</legend>
          <SkeletonTheme baseColor="#2a2438" highlightColor="#1a1622">
            {loading ? (
              <Skeleton count={4} height={20} />
            ) : (
              <div className="fade-in">
                {todayDeadlines.length > 0 ? (
                  <div className="transparent-table-wrapper">
                    <table className="transparent-table">
                      <thead>
                        <tr>
                          <th>Mapel</th>
                          <th>Keterangan</th>
                          <th>Deadline</th>
                        </tr>
                      </thead>
                      <tbody>
                        {todayDeadlines.map((deadline, index) => (
                          <tr key={index}>
                            <td>{deadline.mapel}</td>
                            <td>{deadline.keterangan}</td>
                            <td>{deadline.deadline}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h3><b>TIDAK ADA/BELUM UPDATE</b></h3>
                )}
              </div>
            )}
          </SkeletonTheme>
        </fieldset>
        <fieldset className="info-box">
          <legend>Besok</legend>
          <SkeletonTheme baseColor="#2a2438" highlightColor="#1a1622">
            {loading ? (
              <Skeleton count={4} height={20} />
            ) : (
              <div className="fade-in">
                {tomorrowDeadlines.length > 0 ? (
                  <div className="transparent-table-wrapper">
                    <table className="transparent-table">
                      <thead>
                        <tr>
                          <th>Mapel</th>
                          <th>Keterangan</th>
                          <th>Deadline</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tomorrowDeadlines.map((deadline, index) => (
                          <tr key={index}>
                            <td>{deadline.mapel}</td>
                            <td>{deadline.keterangan}</td>
                            <td>{deadline.deadline}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h3><b>TIDAK ADA/BELUM UPDATE</b></h3>
                )}
              </div>
            )}
          </SkeletonTheme>
        </fieldset>
        <fieldset className="info-box">
          <legend>Minggu Ini</legend>
          <SkeletonTheme baseColor="#2a2438" highlightColor="#1a1622">
            {loading ? (
              <Skeleton count={4} height={20} />
            ) : (
              <div className="fade-in">
                {thisWeekDeadlines.length > 0 ? (
                  <div className="transparent-table-wrapper">
                    <table className="transparent-table">
                      <thead>
                        <tr>
                          <th>Mapel</th>
                          <th>Keterangan</th>
                          <th>Deadline</th>
                        </tr>
                      </thead>
                      <tbody>
                        {thisWeekDeadlines.map((deadline, index) => (
                          <tr key={index}>
                            <td>{deadline.mapel}</td>
                            <td>{deadline.keterangan}</td>
                            <td>{deadline.deadline}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h3><b>TIDAK ADA/BELUM UPDATE</b></h3>
                )}
              </div>
            )}
          </SkeletonTheme>
        </fieldset>
      </div>
    </div>
  );
}

export default Home;
