import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { format, isThursday, nextThursday, parseISO } from 'date-fns';
import './Task.css';

function Tasks() {
  const [tasks, setTasks] = useState([]);
  const [activities, setActivities] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [loadingActivities, setLoadingActivities] = useState(true);

  const fetchTaskData = () => {
    const tasksRef = db.ref('list_tugas');
    tasksRef.once('value', (snapshot) => {
      const tasksData = snapshot.val() || [];
      const sortedTasks = Object.values(tasksData).sort((a, b) => {
        return new Date(a.deadline) - new Date(b.deadline);
      });
      setTasks(sortedTasks);
      setLoadingTasks(false);
    });
  };

  const fetchActivityData = () => {
    const activitiesRef = db.ref('activities');
    activitiesRef.once('value', (snapshot) => {
      const activitiesData = snapshot.val() || [];
      const activitiesList = Object.values(activitiesData);

      const nextThu = nextThursday(new Date());
      activitiesList.push({
        name: 'Olahraga',
        time: nextThu.toISOString()
      });

      setActivities(activitiesList);
      setLoadingActivities(false);
    });
  }

  useEffect(() => {
    fetchTaskData();
    fetchActivityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className={`task-container ${loadingTasks ? 'loading' : ''}`}>
        <div className="task-header">
          <h2>Tasks</h2>
        </div>
        <div className="task-info">
          <div className="task-transparent-table-wrapper">
            <SkeletonTheme baseColor="#2a2438" highlightColor="#1a1622">
              <table className="task-transparent-table">
                <thead>
                  <tr>
                    <th>Mapel</th>
                    <th>Keterangan</th>
                    <th>Deadline</th>
                  </tr>
                </thead>
                <tbody className="fade-in">
                  {loadingTasks ? (
                    Array.from({ length: 3 }).map((_, index) => (
                      <tr key={index}>
                        <td><Skeleton height={20} /></td>
                        <td><Skeleton height={20} /></td>
                        <td><Skeleton height={20} /></td>
                      </tr>
                    ))
                  ) : (
                    tasks.length > 0 ? (
                      tasks.map((task, index) => (
                        <tr key={index}>
                          <td>{task.mapel}</td>
                          <td>{task.keterangan}</td>
                          <td>{format(parseISO(task.deadline), "dd/MM/yyyy HH:mm 'WIT'")}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3"><h3>TIDAK ADA/BELUM UPDATE</h3></td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </SkeletonTheme>
          </div>
        </div>
      </div>

      <div className="activity-container">
        <div className="activity-header">
          <h2>Activities</h2>
        </div>
        <div className="activity-info">
          <div className="activity-transparent-table-wrapper">
            <SkeletonTheme baseColor="#2a2438" highlightColor="#1a1622">
              <table className="activity-transparent-table">
                <thead>
                  <tr>
                    <th>Nama</th>
                    <th>Waktu</th>
                  </tr>
                </thead>
                <tbody className="fade-in">
                  {loadingActivities ? (
                    Array.from({ length: 3 }).map((_, index) => (
                      <tr key={index}>
                        <td><Skeleton height={20} /></td>
                        <td><Skeleton height={20} /></td>
                      </tr>
                    ))
                  ) : (
                    activities.length > 0 ? (
                      activities.map((activity, index) => (
                        <tr key={index}>
                          <td>{activity.name}</td>
                          <td>{isThursday(new Date(activity.time)) ? "Hari Kamis" : format(new Date(activity.time), "dd/MM/yyyy HH:mm 'WIT'")}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="2"><h3>TIDAK ADA/BELUM UPDATE</h3></td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </SkeletonTheme>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tasks;
