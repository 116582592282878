import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyArZT4uLB5zggtqd-C3ESCT-synCxvnYMQ",
    authDomain: "class-web-4ecd1.firebaseapp.com",
    databaseURL: "https://class-web-4ecd1-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "class-web-4ecd1",
    storageBucket: "class-web-4ecd1.appspot.com",
    messagingSenderId: "284993594713",
    appId: "1:284993594713:web:3da02623741c3b0aeb5848",
    measurementId: "G-H7E4HZ4358"
};

firebase.initializeApp(firebaseConfig)
const db = firebase.database()

export { db }