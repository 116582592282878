import React, { useState, useEffect } from 'react';
import { Button, Modal, TextField, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { db } from '../../firebase';
import './Admin.css';

const Admin = () => {
  const [showModal, setShowModal] = useState(false);
  const [mapel, setMapel] = useState('');
  const [keterangan, setKeterangan] = useState('');
  const [deadlineDate, setDeadlineDate] = useState(new Date());
  const [tasks, setTasks] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [taskIdToEdit, setTaskIdToEdit] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [password, setPassword] = useState('');

  useEffect(() => {
    const tasksRef = db.ref('list_tugas');
    tasksRef.on('value', (snapshot) => {
      const tasksData = snapshot.val();
      if (tasksData) {
        const tasksArray = Object.keys(tasksData).map((key) => ({
          id: key,
          ...tasksData[key],
        }));
        setTasks(tasksArray);
      } else {
        setTasks([]);
      }
    });
  }, []);

  const formatDateToString = (date) => {
    if (!date) return '';
    return format(date, "dd/MM/yyyy HH:mm 'WIT'");
  };

  const addTask = () => {
    const newTaskRef = db.ref('list_tugas').push();
    newTaskRef.set({
      mapel,
      keterangan,
      deadline: deadlineDate.toISOString(),
    });
    setMapel('');
    setKeterangan('');
    setDeadlineDate(new Date());
    setShowModal(false);
  };

  const deleteTask = (taskId) => {
    db.ref(`list_tugas/${taskId}`).remove();
  };

  const editTask = (taskId) => {
    const taskToEdit = tasks.find((task) => task.id === taskId);
    if (taskToEdit) {
      setMapel(taskToEdit.mapel);
      setKeterangan(taskToEdit.keterangan);
      setDeadlineDate(new Date(taskToEdit.deadline));
      setEditMode(true);
      setTaskIdToEdit(taskId);
      setShowModal(true);
    }
  };

  const updateTask = () => {
    db.ref(`list_tugas/${taskIdToEdit}`).update({
      mapel,
      keterangan,
      deadline: deadlineDate.toISOString(),
    });
    setMapel('');
    setKeterangan('');
    setDeadlineDate(new Date());
    setEditMode(false);
    setTaskIdToEdit(null);
    setShowModal(false);
  };

  const handleLogin = () => {
    if (password === 'xiMATH1607') {
      setAuthenticated(true);
    } else {
      alert('Incorrect password!');
    }
  };

  return (
    <div className="admin-container">
      {!authenticated ? (
        <div className="login-container">
          <h2>Admin Login</h2>
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input"
          />
          <Button variant="contained" onClick={handleLogin}>
            Login
          </Button>
        </div>
      ) : (
        <div className="task-manager-container">
          <div className="task-manager-menu">
            <Button variant="contained" onClick={() => setShowModal(true)}>
              Add Task
            </Button>
            <Modal open={showModal} onClose={() => setShowModal(false)}>
              <div className="modal-container">
                <h3>{editMode ? 'Edit Task' : 'Add New Task'}</h3>
                <TextField
                  label="Mapel"
                  value={mapel}
                  className="input"
                  onChange={(e) => setMapel(e.target.value)}
                />
                <TextField
                  label="Keterangan"
                  multiline
                  className="input"
                  value={keterangan}
                  onChange={(e) => setKeterangan(e.target.value)}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    label="Deadline"
                    value={deadlineDate}
                    className="input"
                    onChange={(newValue) => setDeadlineDate(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    ampm={false}
                    inputFormat="dd/MM/yyyy HH:mm"
                  />
                </LocalizationProvider>
                <div className="modal-actions">
                  <Button onClick={() => setShowModal(false)}>Close</Button>
                  {editMode ? (
                    <Button variant="contained" onClick={updateTask}>
                      Update
                    </Button>
                  ) : (
                    <Button variant="contained" onClick={addTask}>
                      Add
                    </Button>
                  )}
                </div>
              </div>
            </Modal>

            <div className="table-container">
              <Table className="table">
                {tasks.length > 0 ? (
                  <>
                    <TableHead>
                      <TableRow>
                        <TableCell className="mapel">Mapel</TableCell>
                        <TableCell className="keterangan">Keterangan</TableCell>
                        <TableCell className="deadline">Deadline</TableCell>
                        <TableCell className="action">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tasks.map((task) => (
                        <TableRow key={task.id}>
                          <TableCell>{task.mapel}</TableCell>
                          <TableCell>{task.keterangan}</TableCell>
                          <TableCell>{formatDateToString(new Date(task.deadline))}</TableCell>
                          <TableCell>
                            <Button variant="contained" onClick={() => editTask(task.id)}>Edit</Button>
                            <Button variant="contained" color="error" onClick={() => deleteTask(task.id)}>Delete</Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>
                ) : (
                  <h2><b>TIDAK ADA TUGAS/BELUM UPDATE</b></h2>
                )}
              </Table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
