import React, { useEffect, useState, useRef } from 'react';
import { db } from '../../firebase';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import './Schedule.css';

function Schedule() {
  const [schedules, setSchedules] = useState({
    Senin: [],
    Selasa: [],
    Rabu: [],
    Kamis: [],
    Jumat: [],
    Sabtu: []
  });

  const [selectedDay, setSelectedDay] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [closingPopup, setClosingPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const scheduleRef = useRef(null);

  useEffect(() => {
    fetchScheduleData();
  }, []);

  const fetchScheduleData = () => {
    const days = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];

    Promise.all(
      days.map(day => {
        const dayRef = db.ref(`list_mapel/${day}`);
        return dayRef.once('value').then(snapshot => snapshot.val() || []);
      })
    ).then(data => {
      const updatedSchedules = {};
      days.forEach((day, index) => {
        updatedSchedules[day] = data[index].map(schedule => ({
          ...schedule,
          showRoom: false
        }));
      });
      setSchedules(updatedSchedules);
      setLoading(false);
    }).catch(error => {
      console.error('Error fetching schedules:', error);
      setLoading(false);
    });
  };

  const showScheduleDetails = (day) => {
    setSelectedDay(day);
    setShowPopup(true);
  };

  const closeScheduleDetails = () => {
    setClosingPopup(true);
    setTimeout(() => {
      setShowPopup(false);
      setSelectedDay(null);
      setClosingPopup(false);
    }, 300);
  };

  const renderAgamaContent = (day) => {
    let hasAgama = false;

    return schedules[day].map((data, idx) => {
      const isAgama = data.mapel.includes('Agama Islam') || data.mapel.includes('Agama Katolik') || data.mapel.includes('Agama Protestan');

      if (isAgama && !hasAgama) {
        hasAgama = true;
        return (
          <div className="schedule-item" key={idx}>
            <p className="fade-in">Agama</p>
          </div>
        );
      } else if (isAgama && hasAgama) {
        return null;
      } else {
        return (
          <div className="schedule-item" key={idx}>
            <p className="fade-in">{data.mapel}</p>
          </div>
        );
      }
    }).filter(Boolean);
  };

  const todayDayName = format(new Date(), 'EEEE', { locale: id });

  return (
    <div className="schedule-container">
      <h2 className="schedule-title">Jadwal Pelajaran</h2>
      <SkeletonTheme baseColor="#2a2438" highlightColor="#1a1622">
        <div className={`schedule-content ${loading ? 'loading' : ''}`} ref={scheduleRef}>
          {Object.keys(schedules).map((day, index) => (
            <div className="schedule-info" key={index}>
              <fieldset className="schedule-info-box" onClick={() => showScheduleDetails(day)}>
                <legend style={{ fontWeight: day === todayDayName ? 'bold' : 'normal' }}>{day}</legend>
                <div className="schedule-box">
                  {loading ? (
                    <Skeleton count={4} height={25} />
                  ) : (
                    renderAgamaContent(day)
                  )}
                </div>
              </fieldset>
            </div>
          ))}
        </div>
      </SkeletonTheme>

      {showPopup && (
        <div className={`schedule-popup ${closingPopup ? 'fadeOut' : ''}`} onClick={closeScheduleDetails}>
          <div className={`schedule-popup-content ${closingPopup ? 'slideOut' : ''}`}>
            <span className="close-popup" onClick={closeScheduleDetails}>&times;</span>
            <h3>Detail Jadwal Hari {selectedDay}</h3>
            <table className="schedule-table">
              <thead>
                <tr>
                  <th>Nama Pelajaran</th>
                  <th>Ruangan</th>
                </tr>
              </thead>
              <tbody>
                {schedules[selectedDay].map((schedule, idx) => (
                  <tr key={idx}>
                    <td>{schedule.mapel}</td>
                    <td>{schedule.ruangan}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default Schedule;
