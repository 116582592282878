import React from 'react';
import Navigation from './components/Navigation';
import Header from './components/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Task from './components/Task';
import Schedule from './components/Schedule';
import Admin from './components/Admin';
import './App.css';

function App() {
  return (
    <div className="container">
      <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/task-activity" element={<Task />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
        <Navigation />
      </Router>
    </div>
  );
}

export default App
